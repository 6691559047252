import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import './../css/App.scss';

class StartPage extends Component {
  render() {
    const { title, text, buttonText, pdf } = this.props.content;
    return (
        <section className='content slider row vcenter'>
            <div className='slide animated fadeIn'>
            <h1>{ReactHtmlParser(title)}</h1>
            <p className='subtitle'>{ReactHtmlParser(text)}</p>
            <a 
              className='cat-button cat-bubble animated pulse infinite'
              href={`/${pdf}`}
              target='_blank'
              rel='noopener noreferrer'>
              {ReactHtmlParser(buttonText)}
            </a>
            </div>
      </section>
    );
  }
}

export default StartPage;