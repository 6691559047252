import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import './../css/App.scss';

class Psychotest extends Component {
  initQuiz = () => {
    this.props.resetQuiz();
    this.props.history.push(`/psychotest/test`);
  }
  render() {
    const { title, text, buttonText } = this.props.content;
    return (
        <section className="content slider row vcenter">
            <div className="slide animated fadeIn">
            <h1>{ReactHtmlParser(title)}</h1>
            <p className="subtitle">{ReactHtmlParser(text)}</p>
            <button className="cat-button cat-bubble animated pulse infinite" onClick={this.initQuiz}>{ReactHtmlParser(buttonText)}</button>
            </div>
      </section>
    );
  }
}

export default Psychotest;