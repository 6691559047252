import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

class About extends Component {
  render() {
    const { title, subTitle, text } = this.props.content;
    return (
      <section className="content row">
        <div className="slide animated fadeIn">
            <h2>{ReactHtmlParser(title)}</h2>
            <p className="subtitle">{ReactHtmlParser(subTitle)}</p>
            {ReactHtmlParser(text)}
        </div>
      </section>
    );
  }
}

export default About;