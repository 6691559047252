import React, { Component } from 'react';
import Quiz from './Quiz';

class App extends Component {
  goToFinalResult = () => {
    this.props.history.push(`/result`);
  }
  render() {
    return (
        <Quiz 
          data={this.props.questions} 
          resultButton = {this.props.resultButton}
          displayNextSlide={this.props.displayNextSlide} 
          goToFinalResult={this.goToFinalResult} 
        />
    );
  }
}

export default App;