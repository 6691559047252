import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import logo from './../logo.png';

class Header extends Component {
  goToIndex = (e) => {
    e.preventDefault();
    this.props.history.push('/');
  }
  goToAbout = (e) => {
    e.preventDefault();
    this.props.history.push('/about');
  }
  goToTest = (e) => {
    e.preventDefault();
    this.props.history.push('/psychotest');
  }
  render() {
    const { nav, id } = this.props.content;
    const availableLang = id ===  "de" ? "EN" : "DE";
    return (
      <header className="header row">
        <div className="logo">
          <a href={"/"} alt="Cindy-cat logo" onClick={this.goToIndex}>
            <img src={logo} width="120" alt="Logo" />
          </a>
        </div>
        <nav className="main-nav">
          <ul>
            <li onClick={this.goToIndex}>{nav.home}</li>
            <li onClick={this.goToTest}>{nav.psychotest}</li>
            <li onClick={this.goToAbout}>{nav.about}</li>
            <li className="lang-selector">
              <span className="lang-link" onClick={this.props.switchLang}>
                  {availableLang}
              </span>
            </li>
          </ul>
        </nav>
      </header>
    );
  }
}

export default withRouter(Header);