import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

class JoinUs extends Component {
  render() {
    const { about, resultFinal } = this.props.content;
    return (
      <section className="content row">
        <h2>{ReactHtmlParser(resultFinal.text)}</h2>
        <div className="points animated fadeIn">
            <div className="col">
              <h3>{about.title1}</h3>
              <ul>
              {about.points1.map((key, index) => 
                <li key={index}>
                    {ReactHtmlParser(key)}
                </li>)}
              </ul>
            </div>
            <div className="col">
              <h3>{ReactHtmlParser(about.title2)}</h3>
              <ul>
              {about.points2.map((key, index) => 
                <li key={index}>
                    {ReactHtmlParser(key)}
                </li>)}
              </ul>
            </div>
        </div>
      </section>
    );
  }
}

export default JoinUs;