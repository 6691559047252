import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

class ResultFinal extends Component {
  displayMoreInfo = () => {
    this.props.history.push(`/join`);
  }
  render() {
    const { text, buttonText } = this.props.content;
    return (
        <section className="content slider row vcenter">
          <div className="slide">
            <h1>{ReactHtmlParser(text)}</h1>
            <button className="cat-button cat-thinking animated pulse infinite" onClick={this.displayMoreInfo}>{ReactHtmlParser(buttonText)}</button>
          </div>
        </section>
    );
  }
}

export default ResultFinal;