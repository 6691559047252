// quiz data
const questions = {
    de: {
        id: "de",
        question1: {
            id: "question1",
            text: "Finanzierst du dich ganz bzw. teilweise durch Kulturarbeit?",
            answers: [
                {
                    id: "answer1",
                    text: "Ja",
                    nextQuestion: "question2"
                },
                {
                    id: "answer2",
                    text: "Nein",
                    nextQuestion: "question3"
                }
            ]
          },
          question2: {
            id: "question2",
            text: "Wie ist es mit deiner aktuellen Arbeit? <br />Gibt es dafür Geld?",
            answers: [
                {
                    id: "answer1",
                    text: "Ja",
                    nextQuestion: "question4"
                },
                {
                    id: "answer2",
                    text: "Nein",
                    nextQuestion: "question5"
                }
            ]
          },
          question3: {
            id: "question3",
            text: "Machst du dann eine Tätigkeit unbezahlt, für die sonst andere Leute bezahlt werden?",
            answers: [
                {
                    id: "answer1",
                    text: "Ja",
                    result: "result1"
                },
                {
                    id: "answer2",
                    text: "Nein",
                    result: "result2"
                }
            ]
          },
          question4: {
            id: "question4",
            text: "Wird das Produkt bezahlt oder die Arbeit?",
            answers: [
                {
                    id: "answer1",
                    text: "??? Ich hab keinen Vertrag.",
                    nextQuestion: "question6"
                },
                {
                    id: "answer2",
                    text: "PRODUKT: Können deine Auftraggeber immer wieder neue Korrekturen von dir verlangen, sodass deine Arbeit nie fertig ist ?",
                    nextQuestion: "question6"
                },
                {
                    id: "answer3",
                    text: "ARBEITSZEIT: Wird wirklich die komplette Zeit bezahlt, die du reinsteckst?",
                    nextQuestion: "question6"
                }
            ]
          },
          question5: {
            id: "question5",
            text: "Du findest es persönlich oder politisch wichtig, dass diese Arbeit – diese Veranstaltung, <br />dieses Projekt oder diese Tätigkeit in dieser Institution – gemacht wird?",
            text2: "Ist das Ergebnis deiner kostenlosen Arbeit etwas, das für andere komerziell verwertbar ist?",
            answers: [
                {
                    id: "answer1",
                    text: "Nein",
                    nextQuestion: "question10"
                },
                {
                    id: "answer2",
                    text: "Ja",
                    nextQuestion: "question7"
                }
            ]
          },
          question6: {
            id: "question6",
            text: "Wenn du von der Bezahlung Reproduktionskosten wie die für Krankheit, Urlaub, Essen, Rente, Nebenkosten wie Fahrtkosten, Assistent_innen, Miete, Vorleistungen wie Skizzen, Projektmanagement, Netzwerkarbeit und deinen persönlichen Overhead wie Buchführung, Steuerklärung, Rechnungschreiben abziehst, bleibt ein signifikanter Betrag größer Null übrig, von dem du leben kannst ?",
            answers: [
                {
                    id: "answer1",
                    text: "Ja",
                    result: "result3"
                },
                {
                    id: "answer2",
                    text: "Nein",
                    nextQuestion: "question8"
                }
            ]
          },
          question7: {
            id: "question7",
            text: "Gibt es wenigstens andere „benefits“ wie Ruhm, Ehre, Respekt, Lebenslauf, Erfahrung, Kontakte, bezahlte Folgeaufträge ?",
            answers: [
                {
                    id: "answer1",
                    text: "Nein",
                    result: "result4"
                },
                {
                    id: "answer2",
                    text: "Ja",
                    result: "result5"
                }
            ]
          },
          question8: {
            id: "question8",
            text: "Fordern andere Leute mehr für die selbe Tätigkeit?",
            answers: [
                {
                    id: "answer1",
                    text: "Ja",
                    result: "result6"
                },
                {
                    id: "answer2",
                    text: "weiß nicht/ nein",
                    nextQuestion: "question9"
                }
            ]
          },
          question9: {
            id: "question9",
            text: "Ist es sicher, dass wenigstens andere „benefits“ rumkommen wie Ruhm, Ehre, Respekt, Lebenslauf, Erfahrung, Kontakte oder Folgeaufträge?",
            answers: [
                {
                    id: "answer1",
                    text: "Nein",
                    result: "result7"
                },
                {
                    id: "answer2",
                    text: "Ja",
                    result: "result8"
                }
            ]
          },
          question10: {
            id: "question10",
            text: "Macht dir die Tätigkeit Freude? Ist die Tätigkeit ein körperlicher, seelischer, kollektiver Ausgleich zu deiner sonstigen Erwerbsarbeit?",
            answers: [
                {
                    id: "answer1",
                    text: "Ja",
                    result: "result9"
                },
                {
                    id: "answer2",
                    text: "Nein",
                    result: "result10"
                }
            ]
          },
          result1: {
              id: "result1",
              text: "MACH’S NICHT! Überlass das Feld Leuten, die davon leben müssen."
          },
          result2: {
              id: "result2",
              text: "Fein, dann fröne deinem Hobby, viel Spaß!"
          },
          result3: {
              id: "result2",
              text: "Schön. Mach’s! Aber es kommen auch andere Zeiten. Geh in eine Gewerkschaft* und tausch dich mit anderen aus!"
          },
          result4: {
              id: "result4",
              text: "MACH’S NICHT! Dauerhaftes Arbeiten ohne Freude und ohne Lohn ist stressig und macht krank! Mach in der Zeit lieber etwas, was dir Spaß macht, z.B. deine Steuererklärung oder eine Arbeitsrechtsschulung!",
              resultFinal: true
          },
          result5: {
              id: "result5",
              text: "Das ist trotzdem schlimme Ausbeutung. Musst du wirklich immer noch „Lehrgeld“ zahlen? Wie lange machst du das jetzt schon? Wieviel Erfahrungen willst du noch sammeln?",
              resultFinal: true
          },
          result6: {
              id: "result6",
              text: "Warum ist das so? Resultiert deine Leistungsbereitschaft – niedriger Preis, mehr machen als vereinbart etc. – aus der Konkurrenzsituation? Drückst du darum den Lohn? Wenn ja, dann schadet das nicht nur dir sondern auch anderen.",
              resultFinal: true
          },
          result7: {
              id: "result7",
              text: "Das ist schlimme Ausbeutung. Frage die anderen Arbeiter_innen, welchen Lohn sie bekommen, damit ihr euch nicht unbewusst gegenseitig unterbietet! Heimlichtuerei in der Preisgestaltung nützt nur denen, die dir und anderen zu wenig für eure Arbeit bezahlen. Finde Verbündete! Finde heraus, wieviel du fordern musst, damit alle deinen Kosten gedeckt sind!",
              resultFinal: true
          },
          result8: {
              id: "result8",
              text: "Aber wie oft hast du jetzt schon nur für einen schönen Lebenslauf gearbeitet? Wann reichts dir auch mal? FORDERE MEHR GELD! MACH ES SONST NICHT!",
              resultFinal: true
          },
          result9: {
              id: "result9",
              text: "Wunderbar! Aber achte auf deinen Gesamthaushalt als Person bzw. darauf, ob es dir irgendwann mal keine Freude mehr macht! Dann hör auf damit!",
              resultFinal: true
          },
          result10: {
              id: "result10",
              text: "MACH’S NICHT! Dauerhaftes Arbeiten ohne Freude und ohne Lohn ist stressig und macht krank! Mach in der Zeit lieber etwas, was dir Spaß macht, z.B. deine Steuererklärung oder eine Arbeitsrechtsschulung!",
              resultFinal: true
          }
    },
    en: {
        id: "en",
        question1: {
            id: "question1",
            text: "Do you finance yourself entirely, or else partially, through cultural work?",
            answers: [
                {
                    id: "answer1",
                    text: "Yes",
                    nextQuestion: "question2"
                },
                {
                    id: "answer2",
                    text: "No",
                    nextQuestion: "question3"
                }
            ]
          },
          question2: {
            id: "question2",
            text: "Consider your current form of work. Do you receive financial compensation for it?",
            answers: [
                {
                    id: "answer1",
                    text: "Yes",
                    nextQuestion: "question4"
                },
                {
                    id: "answer2",
                    text: "No",
                    nextQuestion: "question5"
                }
            ]
          },
          question3: {
            id: "question3",
            text: "In that case, are you doing an activity without receiving financial compensation, for which  people would otherwise get paid?",
            answers: [
                {
                    id: "answer1",
                    text: "Yes",
                    result: "result1"
                },
                {
                    id: "answer2",
                    text: "No",
                    result: "result2"
                }
            ]
          },
          question4: {
            id: "question4",
            text: "Is it the product, which is paid for, or the working hours that were invested into making it?",
            answers: [
                {
                    id: "answer1",
                    text: "???: I do not work under a contract",
                    nextQuestion: "question6"
                },
                {
                    id: "answer2",
                    text: "Product: Can your employers continuously demand revisions from you, so that your work is never finished?",
                    nextQuestion: "question6"
                },
                {
                    id: "answer3",
                    text: "Working hours: Are truly all the hours paid for, which you invest into your work?",
                    nextQuestion: "question6"
                }
            ]
          },
          question5: {
            id: "question5",
            text: "You believe that it is IMPORTANT -, for personal and/or political reasons - that this work – this event, this project, this activity is accomplished!",
            text2: "Is the result of your free labour something that can be commercially utilized by others?",
            answers: [
                {
                    id: "answer1",
                    text: "No",
                    nextQuestion: "question10"
                },
                {
                    id: "answer2",
                    text: "Yes",
                    nextQuestion: "question7"
                }
            ]
          },
          question6: {
            id: "question6",
            text: "After having deducted reproductive costs, – such as for sick-leave and medicine, holidays, food, pension – ancillary costs, - such as commuting expenses, assistance, rent, upfront costs, such as sketches, project management, networking as well as your personal overhead – accounting, tax returns,  invoices -, from your salary, are you left with a significant amount above zero, from which you can make a living?",
            answers: [
                {
                    id: "answer1",
                    text: "Yes",
                    result: "result3"
                },
                {
                    id: "answer2",
                    text: "No",
                    nextQuestion: "question8"
                }
            ]
          },
          question7: {
            id: "question7",
            text: "Do you at least receive OTHER “BENEFITS” – such as honour, privilege, respect, career opportunity, CV, experience, contacts or subsequent employment?",
            answers: [
                {
                    id: "answer1",
                    text: "No",
                    result: "result4"
                },
                {
                    id: "answer2",
                    text: "Yes",
                    result: "result5"
                }
            ]
          },
          question8: {
            id: "question8",
            text: "Do OTHER people demand MORE for the same form of work?",
            answers: [
                {
                    id: "answer1",
                    text: "Yes",
                    result: "result6"
                },
                {
                    id: "answer2",
                    text: "I don’t know/no",
                    nextQuestion: "question9"
                }
            ]
          },
          question9: {
            id: "question9",
            text: "Are you at least ensured “benefits” of another kind – such as honour, privilege, respect, career opportunity, CV, experience, contacts or subsequent employment?",
            answers: [
                {
                    id: "answer1",
                    text: "No",
                    result: "result7"
                },
                {
                    id: "answer2",
                    text: "Yes",
                    result: "result8"
                }
            ]
          },
          question10: {
            id: "question10",
            text: "Does the accomplishment of this work make you HAPPY? Does this work provide you with a – physical, mental, general, balance to your other employment?",
            answers: [
                {
                    id: "answer1",
                    text: "Yes",
                    result: "result9"
                },
                {
                    id: "answer2",
                    text: "No",
                    result: "result10"
                }
            ]
          },
          result1: {
              id: "result1",
              text: "DON’T DO IT! Leave this field of work to those, who need to earn a living from it!"
          },
          result2: {
              id: "result2",
              text: "Great, continue indulging in your hobby and enjoy!"
          },
          result3: {
              id: "result2",
              text: "Great! Do it! But keep in mind, that you might not remain in such a position indefinitely. Join a labour union and share your experience with others!"
          },
          result4: {
              id: "result4",
              text: "DON’T DO IT! Permanent activity in a field of work that neither gives you joy, nor is recompensed with an adequate wage is stressful and causes illness! Use that time to do something that you like instead, e.g. your tax returns or educating yourself in labour law.",
              resultFinal: true
          },
          result5: {
              id: "result5",
              text: "Even so, this amounts to severe labour EXPLOITATION. Do you really still need to pay for an apprenticeship when that very work could be recompensed? How long have you already been doing that until now? How much more experience do you really need to gather?",
              resultFinal: true
          },
          result6: {
              id: "result6",
              text: "Why is that the case? Does your motivation – low cost, doing more than initially agreed upon etc. – result from a COMPETITIVE SITUATION? Is that the reason for your shirking away from an appropriate wage? If yes, then this not only harms yourself, but others as well.",
              resultFinal: true
          },
          result7: {
              id: "result7",
              text: "Ask your co-workers how much wage they receive, so that you aren’t unknowingly     undermining each other! – Secrecy in processes of pricing are beneficial only for those who seek to pay too little for the work that you and others accomplish. FIND ALLIES! Find out, how much of a raise you should demand, so that all your costs and expenses are met.",
              resultFinal: true
          },
          result8: {
              id: "result8",
              text: "Ask yourself: how often have you already worked simply in order to polish up your resumé? WHEN have you had ENOUGH? Ask for more money! Otherwise don't do it!",
              resultFinal: true
          },
          result9: {
              id: "result9",
              text: "Wonderful! But keep an eye on your overall capacities and your budget as well as on whether this work continues to bring you joy! If it ceases to do so, then don’t continue!",
              resultFinal: true
          },
          result10: {
              id: "result10",
              text: "DON’T DO IT! Permanent activity in a field of work that neither gives you joy, nor is recompensed with an adequate wage is stressful and causes illness! Use that time to do something that you like instead, e.g. your tax returns or educating yourself in labour law",
              resultFinal: true
          }
    }
  };
  
  export default questions;  