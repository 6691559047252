import React, { Component, Fragment } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Header from './Header';
import ResultFinal from './ResultFinal';
import JoinUs from './JoinUs';
import App from './App';
import StartPage from './StartPage';
import Psychotest from './Psychotest';
import NotFound from './NotFound';
import questions from '../questions';
import content from '../content';
import About from './About';

class Router extends Component {
    state = {
        questions: {},
        content: content.de,
        loading: true
    }
    asyncCall = () => {
        return new Promise((resolve) => setTimeout(() => resolve(), 1000));
    }
    getBrowserLang = () => {
        let currentLang = navigator.language || navigator.browserLanguage;
        if (currentLang.indexOf("de") >= 0) {
            currentLang = "de"
        } else {
            currentLang = "en"
        }
        return currentLang;
    }
    getCurrentSlide = () => {
        return this.state.questions["id"]
    }
    displayNextSlide = (nextID) => {
          let lang = this.state.content.id;
          this.setState({
            questions: questions[lang][nextID]
        });
    }
    switchLang = () => {
        if (this.state.content.id === "de") {
            this.setState({
                content: content.en,
                questions: questions.en[this.getCurrentSlide()]
            });
        } else {
            this.setState({
                content: content.de,
                questions: questions.de[this.getCurrentSlide()]
            });
        }
    }
    resetQuiz = (lang) => {
        lang = lang || this.state.content.id;
        this.setState({
            content: content[lang],
            questions: questions[lang].question1
        });
    }
    componentDidMount() {
        const currentLang = this.getBrowserLang() || "de";
        this.resetQuiz(currentLang);
        this.asyncCall().then(() => this.setState({ loading: false }));
    }
    render() {
      const loading = this.state.loading;
      if (loading) {
          return null;
      }
      return (
        <BrowserRouter>
        <Fragment>
            <Header
                content={this.state.content} 
                switchLang={this.switchLang}
            />
            <Switch>
                <Route 
                    exact path='/' 
                    render={
                        props => 
                        <StartPage {...props} 
                            content={this.state.content.startPage}
                        />
                    } 
                />
                <Route 
                    exact path='/psychotest' 
                    render={
                        props => 
                        <Psychotest {...props} 
                            content={this.state.content.psychotest}
                            resetQuiz = {this.resetQuiz}
                        />
                    } 
                />
                <Route 
                    exact path='/psychotest/test' 
                    render={
                        props => 
                        <App {...props} 
                            questions={this.state.questions}
                            resultButton = {this.state.content.result.buttonText} 
                            displayNextSlide={this.displayNextSlide}
                            goToFinalResult ={this.goToFinalResult}
                        />
                    } 
                 />
                <Route 
                    exact path='/result'
                    render={
                        props => 
                        <ResultFinal {...props} 
                            content={this.state.content.resultFinal}
                            test={this.state.test}
                        />
                    } 
                />
                <Route 
                    exact path='/join' 
                    render={
                        props => 
                        <JoinUs {...props} 
                            content={this.state.content}
                        />
                    } 
                />
                <Route 
                    exact path='/about' 
                    render={
                        props => 
                        <About {...props} 
                            content={this.state.content.cindycat}
                        />
                    } 
                />
                <Route component={NotFound} />
            </Switch>
        </Fragment>
        </BrowserRouter>
      );
    }
  }

export default Router;