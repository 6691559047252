import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

class Quiz extends Component {
  render() {
    const { id, text, text2, answers, resultFinal } = this.props.data;
    const resultButton = this.props.resultButton;
    let button;
    if (answers) {
      button = answers.map(key => 
        <button className="show-next button button-big" 
            key={key.id} 
            onClick={() => (key.nextQuestion ? this.props.displayNextSlide(key.nextQuestion) : this.props.displayNextSlide(key.result))}>
            {key.text}
        </button>)
    } else if (resultFinal) {
      button = <button className="show-next cat-button cat-bubble animated pulse infinite" onClick={() => this.props.goToFinalResult()}>{resultButton}</button>
    }
    return (
      <section className="content slider row vcenter">
        <div key={id} className="slide animated slideInUp">
          <h2 className="box">
            { ReactHtmlParser(text)}<br />
            { ReactHtmlParser(text2)}
          </h2>
          {button}
        </div>
      </section>
    );
  }
}

export default Quiz;